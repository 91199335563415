import React, {useEffect} from "react";
import PickedProducts from "../PickedProducts";
import PaymentInfo from "./PaymentInfo";
import PaymentMethods from "./PaymentMethods";

import styles from "./styles.module.scss";

const Payment: React.FC = () => {

  useEffect(() => {
    window.scrollTo(0,0);
  }, []);

  return (
    <div className={styles.payment}>
      <PickedProducts isPayment>
        <PaymentInfo />
        <PaymentMethods />
      </PickedProducts>
    </div>
  );
};

export default Payment;
