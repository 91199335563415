import React, {useCallback} from "react";
import {useSelector} from "react-redux";
import {useAppDispatch} from "store";
import {PAYMENT_METHODS, PAYMENT_METHODS_LABEL} from "../../../consts";
import {STATUS} from "store/consts";

import {clsx} from "clsx";
import styles from "./styles.module.scss";

import type {RootState} from "store";
import {setPaymentMethod} from "../../../../../store/market/slice";

const getMethodContent = (method: string, methods: Record<string, {name: string; slug: string;}>, active: string | null, onChange: (m: string) => void): string | React.ReactNode => {
  const available = Object.values(methods).map(it => it.slug);
  const methodsData = Object.values(methods);
  const label = methodsData.find(it => it.slug === method)?.name || PAYMENT_METHODS_LABEL[method];
  const btn =  <div className={clsx(styles.paymentMethodBtn, {[styles.paymentMethodBtnActive]: method === active})} onClick={() => onChange(method)} />;

  if (available.includes(method)) {
    if (method === PAYMENT_METHODS.GV_BILLING) {
      return (
        <div className={styles.paymentMethod}>
          {btn}
          {label}
          <div className={styles.paymentMethodsImages}>
            <div className={styles.sbp} style={{backgroundImage: `url("./pay-sbp.png")`}}/>
            <div className={styles.visa} style={{backgroundImage: `url("./pay-visa.png")`}}/>
            <div className={styles.mc} style={{backgroundImage: `url("./pay-mc.png")`}}/>
            <div className={styles.mir} style={{backgroundImage: `url("./pay-mir.png")`}}/>
          </div>
        </div>
      );
    }
    return (
      <div className={styles.paymentMethod}>
        {btn}
        {label}
      </div>
    );
  } else {
    return (<div className={clsx(styles.paymentMethod, styles.disabled)}>
      <div className={clsx(styles.paymentMethodBtn, styles.disabled)} />
      {label} (недоступно)
    </div>);
  }
};

const PaymentMethods: React.FC = () => {
  const paymentInfo = useSelector((s: RootState) => s.market.cart?.paymentInfo);
  const loadingPayment = useSelector((s: RootState) => s.market.loadingPayment);
  const active = useSelector((s: RootState) => s.market.paymentMethod);
  const dispatch = useAppDispatch();

  const handleChange = useCallback((method: string) => {
    dispatch(setPaymentMethod(method));
  }, []);

  if (loadingPayment === STATUS.LOADING || !paymentInfo) {
    return null;
  }

  return (
    <div className={styles.paymentMethods}>
      {Object.values(PAYMENT_METHODS).map(it => (
        <React.Fragment key={it}>{getMethodContent(it, paymentInfo.paymentMethods, active, handleChange)}</React.Fragment>
      ))}
    </div>
  );
};

export default PaymentMethods;
