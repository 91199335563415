export const MARKET_SCREEN = {
  MAIN: 'MAIN',
  CATEGORY_LIST: 'CATEGORY_LIST',
  PRODUCT: 'PRODUCT',
  CART: 'CART',
  PAYMENT: 'PAYMENT'
} as const;

export const PRODUCT_TYPE = {
  GOODS: 'goods',
  SERVICES: 'services'
} as const;

export const PAYMENT_METHODS = {
  GV_BILLING: 'gv_billing',
  CART: 'cart',
  CASH: 'cash'
};

export const PAYMENT_METHODS_LABEL = {
  [PAYMENT_METHODS.GV_BILLING]: 'Онлайн',
  [PAYMENT_METHODS.CART]: 'Картой',
  [PAYMENT_METHODS.CASH]: 'Наличными'
};
