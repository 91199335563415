import {MARKET_SCREEN}                  from "pages/market/consts";
import {BOTTOM_TYPE}                    from "./consts";
import {STATUS}                         from "store/consts";
import {set}                            from "utils/redux/actions";
import {PREFIX}                         from "store/market/consts";

import type {MarketState}               from "store/market/slice";
import type {AppDispatch}               from "store";

export const getBottomType = (model: MarketState): string | null => {
  const isShowCartBottom = (model.screen === MARKET_SCREEN.CATEGORY_LIST || model.screen === MARKET_SCREEN.MAIN)
  && model.cart && model.cart.items.length > 0;

  switch (true) {
    case model.screen === MARKET_SCREEN.PAYMENT && !!model?.cart?.items.length:
      return BOTTOM_TYPE.PAYMENT;
    case model.screen === MARKET_SCREEN.PRODUCT && model.loadingProduct !== STATUS.LOADING:
      return BOTTOM_TYPE.PRODUCT;
    case model.screen === MARKET_SCREEN.CART:
      return BOTTOM_TYPE.CART;
    case isShowCartBottom:
      return BOTTOM_TYPE.DEFAULT_BOTTOM;
    default:
      return null;
  }
};

export const validateBeforePayment = (model: MarketState, dispatch: AppDispatch): void => {
  const errors: {name?: string, phone?: string, agreement?: string} = {};
  if (!model?.name?.length) {errors.name = 'Обязательное поле';}
  if (!model?.phone?.length) {errors.phone = 'Обязательное поле';}
  if (!model?.agreement) {errors.agreement = 'Обязательное поле';}
  if (model?.name?.length) {errors.name = undefined;}
  if (model?.phone?.length) {errors.phone = undefined;}
  if (model?.agreement) {errors.agreement = undefined;}
  dispatch(set(PREFIX, {errors: {...model.errors, ...errors}}));
};
