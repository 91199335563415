import React from "react";
import {Icon} from "components/Icon";
import {getFormatPrice} from "utils/currency";
import {BASE_URL} from "services";

import styles from "./styles.module.scss";

interface ProductItemProps {
  onClick: (id: number) => void;
  onAdd: (id: number) => void;
  onRemove: (id: number) => void;
  id: number;
  title: string;
  thumbImage?: string;
  productType: string;
  amount?: number;
  price?: number;
  disabled?: boolean;
}

const ProductItem: React.FC<ProductItemProps> = ({
  onClick,
  id,
  title,
  thumbImage,
  onAdd,
  onRemove,
  amount,
  price,
  disabled
}) => {
  let btn;
  if (!amount) {
    btn = <div className={styles.itemProductBtn} onClick={() => onAdd(id)}><span><Icon.Shop/></span><div>{price && getFormatPrice(price, true)}</div></div>;
  } else if (amount && amount > 0) {
    btn = <div className={styles.itemProductBtnCounter}>
      <span className={styles.btnCount} onClick={() => onRemove(id)}><Icon.Minus/></span>
      <span className={styles.btnAmount}>{getFormatPrice(amount)}</span>
      <span className={styles.btnCount} onClick={() => onAdd(id)}><Icon.Plus/></span></div>;
  }

  const imgStyles = thumbImage ? {backgroundImage: `url(${BASE_URL}${thumbImage})`} : {};

  return (
    <div className={styles.item}>
      <div className={styles.itemImage} style={imgStyles} onClick={() => {onClick(id);}}/>
      <div className={styles.itemInner}>
        <div className={styles.itemLabel} onClick={() => {!disabled && onClick(id);}}>{title}</div>
        {btn}
      </div>
    </div>
  );
};

export default ProductItem;
