import React, {useCallback} from "react";
import {useAppDispatch} from "store";
import {useSelector} from "react-redux";
import {deleteItemProduct} from "store/market/thunk";
import Item from "./Item";
import groupBy from "utils/groupBy";
import declOfNum from "utils/declOfNum";
import {getFormatPrice} from "utils/currency";
import ShortItem from "./ShortItem";
import {debouncedAddProduct, debouncedDeleteProduct, getAmountProduct} from "store/market/helpers";
import {STATUS} from "store/consts";

import styles from "./styles.module.scss";

import type {RootState} from "store";
import type {CartItemPrepared} from "pages/market/Model/Marketplace";

function getPreparedItem(data: Record<string, CartItemPrepared[]>) {
  return Object.values(data).map((it) => {
    const category = it?.[0]?.product?.categories?.[0];
    const items = it?.sort((a, b) => a.product.id - b.product.id);
    return {title: category?.title, id: category?.id, items};
  }) as {items: CartItemPrepared[], title: string, id: number}[];
}

function getSums(data: Record<string, CartItemPrepared[]>) {
  return Object.entries(data).map(([key, value]) => {
    let sum = 0;
    let amount = 0;

    value.forEach(it => {
      amount += it.quantity;
      sum += it.product.price * it.quantity;
    });
    return {name: key, value: sum, amount, order: key === 'goods' ? 1 : 2};
  });
}

function getSumName(name: string, amount: number): string | undefined {
  if (name === 'services') {
    return `${amount} ${declOfNum(amount, ['услуга', 'услуги', 'услуг'])}`;
  }
  if (name === 'goods') {
    return `${amount} ${declOfNum(amount, ['товар', 'товара', 'товаров'])}`;
  }
}

const PickedProducts: React.FC<{isPayment?: boolean, children?: React.ReactElement | React.ReactElement[]}> = ({isPayment, children}) => {
  const model = useSelector((s: RootState) => s.market);
  const dispatch = useAppDispatch();

  const deleteItem = useCallback((productId: number, categoryId: number) => {
    dispatch(deleteItemProduct({productId, categoryId}));
  }, []);

  const add = useCallback((productId: number, categoryId: number) => {
    dispatch(debouncedAddProduct(productId, categoryId));
  }, []);

  const remove = useCallback((productId: number, categoryId: number) => {
    dispatch(debouncedDeleteProduct(productId, categoryId));
  }, []);

  let content: React.ReactElement | React.ReactElement[] | null = null;
  let sumContent: React.ReactElement | React.ReactElement[] | null = null;

  if (model.cart?.items?.length) {
    const groupedItems = groupBy(model.cart?.items, ({product}) => product?.categories?.[0]?.id);
    const prepared = getPreparedItem(groupedItems);

    content = prepared.map((gr, i) => (
      <div className={styles.items} key={i}>
        <div className={styles.itemsCategory}>
          <div className={styles.itemsCategoryTitle}>{gr.title}</div>
        </div>
        {gr.items?.map((it, j) => {
          if (isPayment) {return <ShortItem key={`${i}-${j}`} {...it} />;}

          const amount = it?.product && getAmountProduct(it.product.categories[0].id, it.product.id, model) || 0;
          const disabled = model.loadingCart === STATUS.LOADING && (!amount || amount === 0);
          const disableDelete = !amount || amount === 0;

          return  (
            <Item
              {...it}
              key={`${i}-${j}`}
              amount={amount}
              add={(id, catId) => {
                !disabled && add(id, catId);
              }}
              remove={(id, catId) => {
                !disableDelete && remove(id, catId);
              }}
              deleteItem={deleteItem}
            />
          );
        })}
      </div>
    ));

    const typedItems = groupBy(model.cart?.items, ({product}) => product?.productType);
    const sumItems = getSums(typedItems).sort((a, b) => a.order - b.order).map((it, i) => (
      <div key={i} className={styles.amountItem}>
        <span>{getSumName(it.name, it.amount)}</span>
        <span>{getFormatPrice(it.value, true)}</span>
      </div>
    ));

    sumContent = (
      <div className={styles.amountCard}>
        {sumItems}
        <div className={styles.amountSum}>
          <span>Итого:</span>
          <span>{getFormatPrice(model?.cart?.totalCost, true)}</span>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      {content}
      {children}
      {sumContent}
    </div>
  );
};

export default PickedProducts;
