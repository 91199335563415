import React, {
  memo, useCallback,
  useEffect, useMemo
}                                   from 'react';
import {useSelector}                from "react-redux";
import {useParams}                  from "react-router";
import {useSearchParams}            from "react-router-dom";
import {useAppDispatch}             from "store";
import {BottomSheet}                from "components/BottomSheet";
import Layout                       from './components/Layout';
import ProductsList                 from './components/ProductsList';
import CategoriesList               from './components/CategoriesList';
import Payment                      from './components/Payment';
import Product                      from './components/Product';
import MainLoader                   from "./components/MainLoader";
import Support                      from "./components/Support";
import Bottom                       from "./components/Bottom";
import Cart                         from "./components/Cart";
import {MARKET_SCREEN}              from "../consts";
import {
  setCategoryId, setOpenedConditions,
  setProductId, setScreen
}                                   from "store/market/slice";
import {initMarket}                 from "store/market/thunk";
import {STATUS}                     from "store/consts";
import {getBottomType}              from "./components/Bottom/helpers";

import styles                       from "./styles.module.scss";

import type {RootState}             from "store";

function Market() {
  const {slug} = useParams();
  const [params, setParams] = useSearchParams();
  const model = useSelector((s: RootState) => s.market);
  const dispatch = useAppDispatch();
  const bottomType = useMemo(() => getBottomType(model), [model]);

  useEffect(() => {
    dispatch(initMarket({slug, params}));
  }, []);

  const onBack = useCallback((scr: valueof<typeof MARKET_SCREEN>) => {
    if (model.screen === MARKET_SCREEN.CATEGORY_LIST) {
      dispatch(setCategoryId(null));
      if (params.has('category')) {
        params.delete('category');
        setParams(params);
      }
    }
    if (model.screen === MARKET_SCREEN.PRODUCT) {
      dispatch(setProductId(null));
      if (params.has('product')) {
        params.delete('product');
        setParams(params);
      }
    }
    dispatch(setScreen(scr));
    typeof window !== "undefined" && window.scrollTo(0,0);
  }, [model.screen]);

  const closeConditions = useCallback(() => {
    dispatch(setOpenedConditions(false));
  }, []);

  if (model.loading === STATUS.LOADING) {
    return <MainLoader />;
  }

  let content, back, description, title, image = model?.image, reversed = true;
  switch(model.screen) {
  case MARKET_SCREEN.CATEGORY_LIST:
    content = <ProductsList />;
    back = () => onBack(MARKET_SCREEN.MAIN);
    title = model.category?.title || '';
    description = model.market?.sections?.[0].title || '';
    image = model.category?.coverImage || model?.image;
    break;
  case MARKET_SCREEN.PRODUCT:
    content = <Product />;
    back = () => onBack(MARKET_SCREEN.CATEGORY_LIST);
    title = model.product?.title || '';
    description = model.category?.title || '';
    image = model.category?.coverImage || model?.image;
    break;
  case MARKET_SCREEN.CART:
    content = <Cart />;
    back = () => onBack(MARKET_SCREEN.MAIN);
    title = 'Корзина';
    break;
  case MARKET_SCREEN.PAYMENT:
    content = <Payment />;
    back = () => onBack(MARKET_SCREEN.CART);
    title = 'Оформление';
    break;
  default:
    content = <CategoriesList data={model.market?.sections} />;
    title = model.objectName;
    description = model.fullAddress;
    reversed = false;
    break;
  }

  return (
    <div className="page">
      <Layout
        title={title}
        description={description}
        image={image}
        back={back}
        reversed={reversed}
        hasBottom={!!bottomType}
      >
        {content}
        <Support />
      </Layout>
      <Bottom type={bottomType} />
      <BottomSheet
        isOpen={model.openedConditions}
        handleClose={closeConditions}
        title="Условия пользования сервисом"
      >
        <div className={styles.conditions}>{model?.market?.conditions}</div>
      </BottomSheet>
    </div>
  );
}

export default memo(Market);
