import React, {useCallback, useEffect} from "react";
import {setProductId, setScreen} from "store/market/slice";
import {MARKET_SCREEN} from "pages/market/consts";
import {useSearchParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {useAppDispatch} from "store";
import EmptyProducts from "./EmptyProducts";
import {getCategoryInfo} from "store/market/thunk";
import {STATUS} from "store/consts";
import {debouncedAddProduct, debouncedDeleteProduct, getAmountProduct} from "store/market/helpers";
import ProductItem from "./ProductItem";

import styles from "./styles.module.scss";

import type {RootState} from "store";

const ProductsList: React.FC = () => {
  const [params, setParams] = useSearchParams();
  const model = useSelector((s: RootState) => s.market);
  const dispatch = useAppDispatch();

  const onClick = useCallback((id: number) => {
    params.append('product', String(id));
    setParams(params);
    dispatch(setProductId(id));
    dispatch(setScreen(MARKET_SCREEN.PRODUCT));
  }, []);

  const add = useCallback(async (id: number) => {
    if (model.categoryId) {dispatch(debouncedAddProduct(id, model.categoryId));}
  }, [model.categoryId]);

  const remove = useCallback(async (id: number) => {
    if (model.categoryId) {dispatch(debouncedDeleteProduct(id, model.categoryId));}
  }, [model.categoryId]);

  useEffect(() => {
    if (model.categoryId) {
      typeof window !== "undefined" && window.scrollTo(0,0);
      dispatch(getCategoryInfo(model.categoryId));
    }
  }, []);

  if (!model.category) {return null;}

  return (
    <div className={styles.wrapper}>
      <div className={styles.items}>
        {model.loadingCategory === STATUS.LOADING && <EmptyProducts />}
        {model.category.products.map((it, i: number) => {
          if (!model.category) {return null;}
          const amount = getAmountProduct(model.category.id, it.id, model);
          const disabled = model.loadingCart === STATUS.LOADING && (!amount || amount === 0);

          return (
            <ProductItem
              {...it}
              key={i}
              amount={amount}
              onAdd={add}
              onRemove={remove}
              onClick={onClick}
              disabled={!disabled}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ProductsList;
