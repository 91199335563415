import {createSlice, current}                from '@reduxjs/toolkit';
import {STATUS}                               from "store/consts";
import {
  getObjectInfo, getCategoryInfo,
  getProductInfo, getCart, addProduct,
  clearCart, deleteProduct, deleteItemProduct
}                                             from "./thunk";
import extend, {baseState}                    from "utils/redux/extend";
import {MARKET_SCREEN}                        from "pages/market/consts";
import {ACTION_TYPE, PREFIX}                  from "./consts";
import {addItem, getCategories, getPendingRequestKey} from "./helpers";

import type {
  Category, FormatCart,
  Marketplace, Product
}                                   from "pages/market/Model/Marketplace";
import type {BaseState}             from "utils/redux/Model";

export interface MarketState extends BaseState {
  loading: valueof<typeof STATUS>;
  loadingCategory: valueof<typeof STATUS>;
  loadingProduct: valueof<typeof STATUS>;
  loadingCart: valueof<typeof STATUS>;
  loadingPayment: valueof<typeof STATUS>;
  submitting: boolean;
  market: Marketplace | null;
  categories: Partial<Category & {section: number}>[] | null;
  objectName: string;
  fullAddress: string;
  image?: string;
  screen: valueof<typeof MARKET_SCREEN>
  categoryId?: number;
  productId?: number;
  category?: Category;
  product?: Product;
  cart: FormatCart | null;
  pendingRequests: {
    add: {key: string, quantity: number}[];
    delete: {key: string, quantity: number}[];
  };
  openedConditions: boolean;
  sid: string | null;
  name?: string;
  phone?: string;
  agreement: boolean;
  paymentMethod: string | null;
}

const initialState: MarketState = {
  loading: STATUS.FULFILLED,
  loadingCategory: STATUS.FULFILLED,
  loadingProduct: STATUS.FULFILLED,
  loadingCart: STATUS.FULFILLED,
  loadingPayment: STATUS.FULFILLED,
  screen: MARKET_SCREEN.MAIN,
  market: null,
  categories: null,
  objectName: '',
  fullAddress: '',
  cart: null,
  pendingRequests: {
    add: [],
    delete: []
  },
  openedConditions: false,
  agreement: false,
  sid: null,
  paymentMethod: null,
  ...baseState
};

const slice = createSlice({
  name: PREFIX,
  initialState,
  reducers: {
    setScreen: (state: MarketState, {payload}) => {
      state.screen = payload;
    },
    setCategoryId: (state: MarketState, {payload}) => {
      state.categoryId = payload;

      if (!payload) {
        state.category = undefined;
      }
    },
    setProductId: (state: MarketState, {payload}) => {
      state.productId = payload;

      if (!payload) {
        state.product = undefined;
      }
    },
    addRequest: (state, {payload}) => {
      const currentState = current(state).pendingRequests;
      const key = getPendingRequestKey(payload.categoryId, payload.productId);

      if (payload.action === ACTION_TYPE.ADD) {
        state.pendingRequests.add = addItem(key, currentState.add);
      }
      if (payload.action === ACTION_TYPE.DELETE) {
        state.pendingRequests.delete = addItem(key, currentState.delete);
      }
    },
    clearRequest: (state, {payload}) => {
      const items = current(state).pendingRequests;
      const key = getPendingRequestKey(payload.categoryId, payload.productId);
      const item = items[payload.action].find(it => it.key === key);

      if (payload.action === ACTION_TYPE.ADD && item) {
        state.pendingRequests.add = items.add.filter(it => it.key !== key);
      }
      if (payload.action === ACTION_TYPE.DELETE && item) {
        state.pendingRequests.delete = items.delete.filter(it => it.key !== key);
      }
    },
    setOpenedConditions: (state: MarketState, {payload}) => {
      state.openedConditions = payload;
    },
    setPaymentMethod: (state: MarketState, {payload}) => {
      state.paymentMethod = payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getObjectInfo.fulfilled, (state: MarketState, {payload}) => {
      state.market = payload.marketplace;
      state.image = payload.mainCover;
      state.fullAddress = payload.fullAddress;
      state.objectName = payload.name;
      state.categories = getCategories(payload.marketplace?.sections);

      state.sid = payload.sid;
      state.loading = STATUS.FULFILLED;
    });
    builder.addCase(getObjectInfo.pending, (state: MarketState) => {
      state.loading = STATUS.LOADING;
    });
    builder.addCase(getObjectInfo.rejected, (state: MarketState) => {
      state.loading = STATUS.REJECTED;
    });
    builder.addCase(getCategoryInfo.fulfilled, (state: MarketState, {payload}) => {
      state.category = payload;
      state.categoryId = payload.id;
      state.loadingCategory = STATUS.FULFILLED;
    });
    builder.addCase(getCategoryInfo.pending, (state: MarketState) => {
      state.loadingCategory = STATUS.LOADING;
    });
    builder.addCase(getCategoryInfo.rejected, (state: MarketState) => {
      state.loadingCategory = STATUS.REJECTED;
    });
    builder.addCase(getProductInfo.fulfilled, (state: MarketState, {payload}) => {
      state.product = payload;
      state.productId = payload.id;
      state.loadingProduct = STATUS.FULFILLED;
    });
    builder.addCase(getProductInfo.pending, (state: MarketState) => {
      state.loadingProduct = STATUS.LOADING;
    });
    builder.addCase(getProductInfo.rejected, (state: MarketState) => {
      state.loadingProduct = STATUS.REJECTED;
    });
    builder.addCase(getCart.fulfilled, (state: MarketState, {payload}) => {
      state.cart = payload;
      state.loadingPayment = STATUS.FULFILLED;
    });
    builder.addCase(getCart.pending, (state: MarketState) => {
      state.loadingPayment = STATUS.LOADING;
    });
    builder.addCase(getCart.rejected, (state: MarketState) => {
      state.loadingPayment = STATUS.REJECTED;
    });
    builder.addCase(addProduct.fulfilled, (state: MarketState, {payload}) => {
      state.cart = payload;
      state.sid = payload.sid;
      state.loadingCart = STATUS.FULFILLED;
    });
    builder.addCase(addProduct.pending, (state: MarketState) => {
      state.loadingCart = STATUS.LOADING;
    });
    builder.addCase(addProduct.rejected, (state: MarketState) => {
      state.loadingCart = STATUS.REJECTED;
    });
    builder.addCase(deleteProduct.fulfilled, (state: MarketState, {payload}) => {
      state.cart = payload;
      state.loadingCart = STATUS.FULFILLED;
    });
    builder.addCase(deleteProduct.pending, (state: MarketState) => {
      state.loadingCart = STATUS.LOADING;
    });
    builder.addCase(deleteProduct.rejected, (state: MarketState) => {
      state.loadingCart = STATUS.REJECTED;
    });
    builder.addCase(deleteItemProduct.fulfilled, (state: MarketState, {payload}) => {
      state.cart = payload;
      state.loadingCart = STATUS.FULFILLED;
    });
    builder.addCase(deleteItemProduct.pending, (state: MarketState) => {
      state.loadingCart = STATUS.LOADING;
    });
    builder.addCase(deleteItemProduct.rejected, (state: MarketState) => {
      state.loadingCart = STATUS.REJECTED;
    });
    builder.addCase(clearCart.fulfilled, (state: MarketState) => {
      state.cart = null;
    });
  }
});

export const {
  setScreen,
  setCategoryId,
  setProductId,
  setOpenedConditions,
  setPaymentMethod,
  addRequest,
  clearRequest
} = slice.actions;

export default extend<MarketState>(slice);
