import React, {useCallback} from "react";
import {useSelector} from "react-redux";
import {useAppDispatch} from "store";
import {setOpenedConditions} from "store/market/slice";
import Input from "components/Input";
import Checkbox from "components/Checkbox";
import {getOwnState, handleChange} from "utils/redux/actions";
import FormContextWrapper from "utils/redux/context";
import {PREFIX} from "store/market/consts";

import styles from "./styles.module.scss";

import type {RootState} from "store";

const PaymentInfo = () => {
  const model = useSelector((s: RootState) => s.market);
  const dispatch = useAppDispatch();

  const openConditions = useCallback(() => {
    dispatch(setOpenedConditions(true));
  }, []);

  const getState = getOwnState(PREFIX);
  const onChange = handleChange(PREFIX, getState);
  const checkboxInner = (
    <>
      Принимаю условия <a href={model?.market?.offer} target="_blank" rel="noreferrer">Договора офферты</a> и <a onClick={openConditions}>Условия обработки персональных данных</a>
    </>
  );

  return (
    <div className={styles.paymentInfo}>
      <div className={styles.paymentLabel}>Контактные данные (обязательно):</div>
      <div className={styles.paymentDesc}>С вами свяжется оператор сервиса аренды и подтвердит заказ. </div>
      <FormContextWrapper prefix={PREFIX} actions={{handleChange: onChange}}>
        <Input
          name="name"
          placeholder="Ваше имя"
        />
        <Input
          name="phone"
          inputMode="tel"
          placeholder="+7 123 456 78 90"
        />
        <div className={styles.paymentAgreement}>
          <Checkbox
            name="agreement"
            label={checkboxInner}
          />
        </div>
      </FormContextWrapper>
    </div>
  );
};

export default React.memo(PaymentInfo);
