import React from "react";
import {Icon} from "components/Icon";
import {getFormatPrice} from "utils/currency";

import {clsx} from "clsx";
import styles from "./styles.module.scss";

import type {CartItemPrepared} from "pages/market/Model/Marketplace";

const Item: React.FC<CartItemPrepared & {
  add: (id: number, categoryId: number) => void;
  remove: (id: number, categoryId: number) => void;
  deleteItem: (id: number, categoryId: number) => void;
  isCart?: boolean;
  amount: number;
}> = ({
  product,
  amount,
  add,
  remove,
  deleteItem
}) => {
  const imgStyles = product?.image ? {backgroundImage: `url(${product.image})`} : {};

  return (
    <div className={styles.item}>
      <div className={clsx(styles.itemImage)} style={imgStyles} />
      <div className={styles.itemInfo}>
        <div className={clsx(styles.itemLabel)}>
          <span>{product.title}</span>
          <div className={styles.itemDelete} onClick={() => deleteItem(product.id, product.categories[0].id)}><Icon.Delete/></div>
        </div>
          <div className={styles.itemBottom}>
            <div className={styles.itemCounter}>
              <div className={styles.itemCounterBtn} onClick={() => remove(product.id, product.categories[0].id)}>
                <Icon.Minus/></div>
              <div className={styles.itemCount}>{amount}</div>
              <div className={styles.itemCounterBtn} onClick={() => add(product.id, product.categories[0].id)}>
                <Icon.Plus/></div>
            </div>
            <span className={styles.itemPrice}>{getFormatPrice(amount * product.price, true)}</span>
          </div>
      </div>
    </div>
  );
};

export default Item;
