import {addProduct, deleteProduct} from "store/market/thunk";
import {addRequest, clearRequest, MarketState} from "store/market/slice";
import debounce from "lodash.debounce";
import {set} from "utils/redux/actions";
import {ACTION_TYPE, PREFIX} from "./consts";
import {STATUS} from "../consts";

import type {AppDispatch, RootState} from "store";

export const getCategories = (sections) => {
  return sections?.reduce((acc: {id: number; section: number; title: string; description: string; thumbImage?: string;}[], el) => {
    if (el?.categories?.length) {
      acc = [...acc, ...el.categories];
    }
    return acc;
  }, []);
};

export const getPendingRequestKey = (categoryId: string | number, productId: string | number) => {
  return `${categoryId}_${productId}`;
};

export const debouncedAddProduct = (productId: string | number, categoryId: string | number, quantity = 1) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(set(PREFIX, {loadingCart: STATUS.LOADING}));
    dispatch(addRequest({categoryId, productId, quantity, action: ACTION_TYPE.ADD}));
    executeDebouncedAddProduct(productId, categoryId, dispatch, getState);
};

export const debouncedDeleteProduct = (productId: string | number, categoryId: string | number, quantity = 1) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(set(PREFIX, {loadingCart: STATUS.LOADING}));
    dispatch(addRequest({categoryId, productId, quantity, action: ACTION_TYPE.DELETE}));
    executeDebouncedDeleteProduct(productId, categoryId, dispatch, getState);
  };


const executeDebouncedAddProduct = debounce(
  (productId: string | number, categoryId: string | number, dispatch: AppDispatch, getState: () => RootState) => {
    const pendingRequests = getState().market.pendingRequests.add;
    const key = getPendingRequestKey(categoryId, productId);
    const item = pendingRequests.find(it => it.key === key);
    if (item?.quantity === 0) {return;}

    if (item) {
      dispatch(addProduct({productId: productId, categoryId: categoryId, quantity: item.quantity})).then(() => {
        dispatch(clearRequest({categoryId, productId, action: ACTION_TYPE.ADD}));
      });
    }
  }, 500, {leading: false, trailing: true}
);

const executeDebouncedDeleteProduct = debounce(
  (productId: string | number, categoryId: string | number, dispatch: AppDispatch, getState: () => RootState) => {
    const pendingRequests = getState().market.pendingRequests.delete;
    const key = getPendingRequestKey(categoryId, productId);
    const item = pendingRequests.find(it => it.key === key);
    if (item?.quantity === 0) {return;}

    if (item) {
      dispatch(deleteProduct({productId: productId, categoryId: categoryId, quantity: item.quantity})).then(() =>{
        dispatch(clearRequest({categoryId, productId, action: ACTION_TYPE.DELETE}));
      });
    }
  }, 500, {leading: false, trailing: true}
);

export const getAmountProduct = (categoryId: string | number, productId: string | number, model: MarketState): number | undefined => {
  const product = model.cart?.items.find(it => it.product.id === productId && it.product.categories?.[0].id === categoryId);
  const key = getPendingRequestKey(categoryId, productId);
  const pendingAdd = model.pendingRequests.add.find(it => it.key === key)?.quantity;
  const pendingDelete = model.pendingRequests.delete.find(it => it.key === key)?.quantity;

  if (product && pendingDelete && pendingAdd) {
    return product.quantity + pendingAdd - pendingDelete;
  } else if (product && pendingDelete) {
    return product.quantity - pendingDelete;
  } else if (product && pendingAdd) {
    return product.quantity + pendingAdd;
  } else if (!product && pendingDelete) {
    return pendingDelete;
  } else if (!product && pendingAdd) {
    return pendingAdd;
  }
  return product?.quantity;
};

export const addItem = (key: string, items: {key: string; quantity: number;}[]) => {
  const item = items.find(it => it.key === key);
  const filteredItems = items.filter(it => it.key !== key);

  if (item) {
    return [...filteredItems, {key, quantity: item.quantity + 1}];
  }

  return [...items, {key, quantity: 1}];
};

