import React, {useEffect} from "react";
import {useAppDispatch} from "store";
import {getProductInfo} from "store/market/thunk";
import {useSelector} from "react-redux";
import {getFormatPrice} from "utils/currency";
import EmptyProduct from "./EmptyProduct";
import {STATUS} from "store/consts";
import {BASE_URL} from "services";

import styles from "./styles.module.scss";

import {RootState} from "store";

const Product = () => {
  const model = useSelector((s: RootState) => s.market);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getProductInfo(model.productId as number));
  }, []);

  if (model.loadingProduct === STATUS.LOADING || !model.product) {
    return <EmptyProduct />;
  }

  const imgStyle = model.product.image ?
    {backgroundImage: `url(${BASE_URL}${model.product.image})`} :
    {};

  return (
    <div className={styles.product}>
      <div className={styles.productImage} style={imgStyle}/>
      {model.product.description && <div className={styles.productDesc}>
        <div className={styles.productLabel}>Описание</div>
        <div className={styles.productDescText}>
          {model.product.description}
        </div>
      </div>}
      <div className={styles.productCost}>
        <div className={styles.productLabel}>Стоимость:</div>
        <div className={styles.productCostNumber}>{getFormatPrice(model.product.price, true)}</div>
      </div>
    </div>
  );
};

export default Product;
