import React, {useCallback}             from "react";
import {useSelector}                    from "react-redux";
import {useAppDispatch}                 from "store";
import {useSearchParams}                from "react-router-dom";
import {setScreen}                      from "store/market/slice";
import {Icon}                           from "components/Icon";
import {MARKET_SCREEN}                  from "pages/market/consts";
import {getFormatPrice}                 from "utils/currency";
import {BOTTOM_TYPE}                    from "./consts";
import {getCart}                        from "store/market/thunk";
import {validateBeforePayment}          from "./helpers";
import {STATUS}                         from "store/consts";
import {Loader}                         from "./Loader";
import {
  debouncedAddProduct,
  debouncedDeleteProduct,
  getAmountProduct
}                                       from "store/market/helpers";

import styles                           from "./styles.module.scss";

import type {RootState}                 from "store";

const Bottom: React.FC<{type: string | null}> = ({type}) => {
  const [params, setParams] = useSearchParams();
  const model = useSelector((s: RootState) => s.market);
  const dispatch = useAppDispatch();
  const categoryId = model.categoryId;
  const sectionId = model.categories?.find(cat => cat.id === categoryId)?.section;

  const clearParams = useCallback(() => {
    if (params.has('category')) {
      params.delete('category');
      setParams(params);
    }
    if (params.has('product')) {
      params.delete('product');
      setParams(params);
    }
  }, [params, setParams]);

  const openCart = useCallback(() => {
    clearParams();
    dispatch(setScreen(MARKET_SCREEN.CART));
  }, []);

  const addItem = useCallback(async () => {
    if (model.product?.id && categoryId) {dispatch(debouncedAddProduct(model.product.id, categoryId));}
  }, [model.product?.id, categoryId, sectionId]);

  const removeItem = useCallback(async () => {
    if (model.product?.id && categoryId) {dispatch(debouncedDeleteProduct(model.product.id, categoryId));}
  }, [model.product, categoryId, sectionId]);

  const returnToCategory = useCallback(() => {
    if (params.has('product')) {
      params.delete('product');
      setParams(params);
    }
    dispatch(setScreen(MARKET_SCREEN.CATEGORY_LIST));
  }, [model.category?.title, model?.product]);

  const goToPayment = useCallback(() => {
    dispatch(getCart({check: true}));
    dispatch(setScreen(MARKET_SCREEN.PAYMENT));
  }, [model, dispatch]);

  const goToPay = useCallback(() => {
    validateBeforePayment(model, dispatch);
    dispatch(setScreen(MARKET_SCREEN.PAYMENT));
  }, [model, dispatch]);

  if (!type) {
    return null;
  }

  let content;
  if (type === BOTTOM_TYPE.PAYMENT) {
    content = (
      <div className={styles.innerText} onClick={goToPay}>
        Перейти к оплате
      </div>
    );
  }
  if (type === BOTTOM_TYPE.CART) {
    content = (
      <div className={styles.innerText} onClick={goToPayment}>
        Перейти к оформлению
      </div>
    );
  }
  if (type === BOTTOM_TYPE.PRODUCT) {
    const amount = model?.product && categoryId && getAmountProduct(categoryId, model.product.id, model) || 0;

    const disabled = model.loadingCart === STATUS.LOADING && (!amount || amount === 0);
    const disableDelete = !amount || amount === 0;

    content = (
      <div className={styles.productBottomInner}>
        <div className={styles.counter}>
          <div className={styles.counterBtn} onClick={() => {!disableDelete && removeItem();}}><Icon.Minus /></div>
          <div className={styles.counterValue}>{amount}</div>
          <div className={styles.counterBtn} onClick={() => {!disabled && addItem();}}><Icon.Plus /></div>
        </div>
        <div className={styles.productBottomAction} onClick={returnToCategory}>Добавить</div>
      </div>
    );
  }
  if (type === BOTTOM_TYPE.DEFAULT_BOTTOM) {
    content = (
      <div className={styles.bottomInner}>
        {model.loadingCart === STATUS.LOADING ? <Loader /> : (
          <>
            <div className={styles.bottomButtonInfo}>
              <div className={styles.bottomButtonTotal}><span>Сумма заказа:</span><span>{getFormatPrice(model.cart?.totalCost || 0, true)}</span></div>
              <div className={styles.bottomButtonAmount}><span>Товаров в заказе:</span><span>{model.cart?.itemsCount}</span></div>
            </div>
            <div className={styles.bottomButtonOrder} onClick={openCart}>В корзину</div>
          </>
        )}
      </div>
    );
  }

  return (
    <div className={styles.bottom}>
      {content}
    </div>
  );
};

export default React.memo(Bottom);

