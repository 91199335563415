import {createAsyncThunk}         from '@reduxjs/toolkit';
import * as service               from "services/market";
import {
  getFormattedCart,
  getFormattedCategory,
  getFormattedObject,
  getFormattedProduct,
  getSidFromLs
}                                 from "pages/market/helpers";
import {PREFIX}                   from "./consts";
import {setScreen}                from "./slice";
import {MARKET_SCREEN}            from "pages/market/consts";
import {getItem, setItem}         from "utils/local-storage";
import LS_KEY                     from "utils/local-storage/consts";

import type {RootState}           from "store";

export const initMarket = createAsyncThunk(
  `${PREFIX}/init`,
  async ({slug, params}: {slug?: string; params: URLSearchParams} , {dispatch}) => {

    const sid = getSidFromLs();
    const category = params.get('category');
    const product = params.get('product');

    if (sid) {
      dispatch(getCart({sid}));
    }
    dispatch(getObjectInfo(slug as string));

    if (category) {
      dispatch(getCategoryInfo(category));
    }
    if (product) {
      dispatch(getProductInfo(product));
    }

    if (category && !product) {
      dispatch(setScreen(MARKET_SCREEN.CATEGORY_LIST));
    } else if (category && product) {
      dispatch(setScreen(MARKET_SCREEN.PRODUCT));
    }
  }
);

export const getObjectInfo = createAsyncThunk(
  `${PREFIX}/object`,
  async (slug: string, {rejectWithValue}) => {
    const sid = getSidFromLs();

    try {
      const res = await service.getObject(slug);
      const data = getFormattedObject(res.data);
      return {sid, ...data};
    } catch (e) {
      const err = e as Error;
      return rejectWithValue(err.response.data.code);
    }
  }
);

export const getCategoryInfo = createAsyncThunk(
  `${PREFIX}/category`,
  async (id: number | string, {rejectWithValue}) => {
    try {
      const res = await service.getCategory(id);
      return getFormattedCategory(res.data);
    } catch (e) {
      const err = e as Error;
      return rejectWithValue(err.response.data.code);
    }
  }
);

export const getProductInfo = createAsyncThunk(
  `${PREFIX}/product`,
  async (id: number | string, {rejectWithValue}) => {
    try {
      const res = await service.getProduct(id);
      return getFormattedProduct(res.data);
    } catch (e) {
      const err = e as Error;
      return rejectWithValue(err.response.data.code);
    }
  }
);

export const addProduct = createAsyncThunk(
  `${PREFIX}/add`,
  async (
    {productId, categoryId, quantity}: {productId: string | number, categoryId: string | number, quantity?: number},
    {rejectWithValue, getState}
  ) => {
    const model = getState() as RootState;
    const sid = model[PREFIX].sid;
    const marketId = model[PREFIX].market?.id;

    if (!productId || !categoryId || !marketId) {
      return rejectWithValue('Недостаточно данных');
    }

    try {
      const res = await service.addProduct(productId, categoryId, marketId, quantity || 1, sid);
      if (!sid) {
        setItem(LS_KEY.SID, res.data.result.sid);
      }

      return getFormattedCart(res.data);
    } catch (e) {
      const err = e as Error;
      return rejectWithValue(err.response.data.code);
    }
  }
);

export const deleteProduct = createAsyncThunk(
  `${PREFIX}/delete`,
  async (
    {productId, categoryId, quantity}: {productId: string | number, categoryId: string | number, quantity?: number},
    {rejectWithValue, getState}
  ) => {
    const model = getState() as RootState;
    const sid = model[PREFIX].sid;
    const marketId = model[PREFIX].market?.id;

    if (!productId || !categoryId || !marketId || !sid) {
      return rejectWithValue('Недостаточно данных');
    }

    try {
      const res = await service.deleteProduct(productId, categoryId, marketId, quantity || 1, sid);
      return getFormattedCart(res.data);
    } catch (e) {
      const err = e as Error;
      return rejectWithValue(err.response.data.code);
    }
  }
);

export const deleteItemProduct = createAsyncThunk(
  `${PREFIX}/deleteItem`,
  async (
    {productId, categoryId}: {productId: string | number, categoryId: string | number},
    {rejectWithValue, getState}
  ) => {
    const model = getState() as RootState;
    const sid = model[PREFIX].sid;
    const marketId = model[PREFIX].market?.id;
    const quantity = model[PREFIX].cart?.items.find(({product}) =>
      product.id === productId && product.categories?.[0].id === categoryId
    )?.quantity;

    if (!productId || !categoryId || !marketId || !sid) {
      return rejectWithValue('Недостаточно данных');
    }

    try {
      const res = await service.deleteProduct(productId, categoryId, marketId, quantity || 1, sid);
      return getFormattedCart(res.data);
    } catch (e) {
      const err = e as Error;
      return rejectWithValue(err.response.data.code);
    }
  }
);

export const getCart = createAsyncThunk(
  `${PREFIX}/cart`,
  async (
    {sid, check}: {sid?: string, check?: boolean},
    {rejectWithValue}
  ) => {
    const sidValue = getItem(LS_KEY.SID) || sid;

    if (!sidValue) {
      return rejectWithValue('Нет sid');
    }

    try {
      const res = await service.getCart(sidValue, check);
      return getFormattedCart(res.data);
    } catch (e) {
      const err = e as Error;
      return rejectWithValue(err.response.data.code);
    }
  }
);

export const clearCart = createAsyncThunk(
  `${PREFIX}/clear`,
  async (_, {rejectWithValue, getState}) => {
    const model = getState() as RootState;
    const sid = model[PREFIX].sid;
    const marketId = model[PREFIX].market?.id;

    if (!sid) {
      return rejectWithValue('Нет sid');
    }

    try {
      await service.clearCart(sid, marketId);
    } catch (e) {
      const err = e as Error;
      return rejectWithValue(err.response.data.code);
    }
  }
);


