import React, {useCallback} from "react";
import {useSearchParams} from "react-router-dom";
import {useAppDispatch} from "store";
import {setCategoryId, setScreen} from "store/market/slice";
import {MARKET_SCREEN} from "pages/market/consts";
import {BASE_URL} from "services";

import styles from "./styles.module.scss";

interface CategoriesListProps {
  data?: {
    id: number;
    title: string;
    categories: {
      id: number;
      section: number;
      title: string;
      description: string;
      thumbImage?: string;
    }[];
  }[];
}

interface CategoryItemProps {
  onClick: (id: number) => void;
  id: number;
  section: number;
  title: string;
  description: string;
  thumbImage?: string;
}

const CategoryItem: React.FC<CategoryItemProps> = ({onClick, id, title, thumbImage}) => {
  const imgStyle = thumbImage ?
    {backgroundImage: `url(${BASE_URL}${thumbImage})`} :
    {};

  return (
    <div className={styles.item} style={imgStyle} onClick={() => onClick(id)}>
      <div className={styles.itemLabel}>{title}</div>
    </div>
  );
};

const CategoriesList: React.FC<CategoriesListProps> = ({data}) => {
  const [params, setParams] = useSearchParams();
  const dispatch = useAppDispatch();

  const onClick = useCallback((id: number) => {
    params.append('category', String(id));
    setParams(params);
    dispatch(setCategoryId(id));
    dispatch(setScreen(MARKET_SCREEN.CATEGORY_LIST));
  }, []);

  return (
    <div className={styles.wrapper}>
      {data?.map((el, i: number) => (
        <div key={i} className={styles.category}>
          <div className={styles.categoryTitle}>{el.title}</div>
          <div className={styles.items}>
            {el?.categories.map((it, j: number) => (<CategoryItem key={`${i}-${j}`} {...it} onClick={onClick} />))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default CategoriesList;
