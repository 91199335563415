import React from "react";
import {getFormatPrice} from "utils/currency";

import {clsx} from "clsx";
import styles from "./styles.module.scss";

import type {CartItemPrepared} from "pages/market/Model/Marketplace";

const Item: React.FC<CartItemPrepared> = ({
  product,
  quantity
}) => {
  const imgStyles = product?.image ? {backgroundImage: `url(${product.image})`} : {};

  return (
    <div className={styles.item}>
      <div className={clsx(styles.itemShortImage)} style={imgStyles} />
      <div className={styles.itemShortInfo}>
        <div className={clsx(styles.itemLabel)}><span>{product.title}</span></div>
        <div className={styles.itemShortBlock}>
          <div className={styles.itemPrice}>{getFormatPrice(product.price * quantity, true)}</div>
          <div className={styles.itemAmount}>{quantity} шт.</div>
        </div>
      </div>
    </div>
  );
};

export default Item;
