import React, {useEffect} from "react";
import PickedProducts from "../PickedProducts";

const Cart: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0,0);
  }, []);

  return (
    <PickedProducts />
  );
};

export default Cart;
