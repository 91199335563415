import {api} from "services";

import type {CartResponse} from "pages/market/Model/Marketplace";

export async function getObject(slug: string) {
  return api.get(`open/property-object/item/${slug}/`);
}

export async function getCategory(id: number | string) {
  return api.get(`open/property-object/product-category/item/${id}/`);
}

export async function getProduct(id: number | string) {
  return api.get(`open/property-object/product/item/${id}/`);
}

const MARKET_URL = 'open/marketplace/cart/';

export async function addProduct(
  productId: string | number,
  categoryId: string | number,
  marketId: number,
  quantity: number,
  sid: string | null
) {
  const data = {
    product_item_id: productId,
    category: categoryId,
    quantity,
    action_type: 'add',
    market_id: marketId,
    sid: sid
  };
  return api.post<CartResponse>(MARKET_URL, data);
}

export async function deleteProduct(
  productId: string | number,
  categoryId: string | number,
  marketId: number,
  quantity: number,
  sid: string | null
) {
  const data = {
    product_item_id: productId,
    category: categoryId,
    quantity,
    action_type: 'remove',
    market_id: marketId,
    sid: sid
  };
  return api.delete<CartResponse>(MARKET_URL, {data});
}

export async function clearCart(sid: string, marketId?: number) {
  const data = {sid, market_id: marketId, action_type: 'clear'};
  return api.delete(MARKET_URL, {data});
}

export async function getCart(sid: number | string, check?: boolean) {
  const params = {SID: sid, CheckOut: check};
  return api.get(MARKET_URL, {params});
}
